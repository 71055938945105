import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import ReservationSection from "../../Components/ReservationSection";
import * as Widget2 from "../../Components/Widget2";

class ReservationPage extends Component {
  render() {
    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner08.png" css="margin-bottom: 48px;">
          <div className="mist">
            <div className="title">預約參觀</div>
          </div>
        </Widget2.Banner>

        <Container>
          <ReservationSection textCenter={true} />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;
`;

export default withPage(ReservationPage);
